import * as React from 'react';
import {
  HiOutlineQuestionMarkCircle,
  HiOutlineChevronRight,
  HiClipboardCheck,
} from 'react-icons/hi';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import GoogleButton from 'react-google-button';

import { Message, Loader, Grid, TextField, IconButton } from '../../components';
import { useAuth } from '../../state-management';
import { CURRENT_URL } from '../../utils';
import './styles.scss';

interface SignupProps {
  previous: {
    location: string;
  };
}

const Signup: React.FC<SignupProps & RouteComponentProps> = ({
  history,
  previous,
}) => {
  const { state, signup } = useAuth();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [hideForm, setHideForm] = React.useState<boolean>(false);
  const [msg, setMsg] = React.useState<string>('');

  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [password2, setPassword2] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [firstName, setFirst] = React.useState<string>('');
  const [lastName, setLast] = React.useState<string>('');
  
  let error: string;
  if (state.error?.includes('Unable to log in'))
    error = 'Incorrect username or password';
  else error = state.error;
  
  const onSignup = () => {
    setLoading(true);
    signup(username, password, password2, email, firstName, lastName).then(
      (res: any) => {
        if (res?.status === 201) {
          if (res?.data?.detail) setMsg(res.data.detail);
          else setMsg('Successfully signed up!');
          
          if (msg.includes('e-mail sent')) setHideForm(true)
        }
        setLoading(false);
      }
    );
  };

  const signupForm = (
    <div className='lake-games--signup'>
      <div className='lake-games--signup-header'>Signup</div>

      <Message
        severity={error ? 'error' : 'success'}
        message={error || msg}
      />
  
      {!hideForm ? (
        <>
          <TextField
            value={username}
            id='username'
            label='Username'
            autoComplete='username'
            onChange={(e) => setUsername(e.target.value)}
            handleSubmit={onSignup}
          />
  
        {/* <TextField
          value={firstName}
          id='firstName'
          label='First Name'
          autoComplete='firstName'
          onChange={(e) => setFirst(e.target.value)}
          handleSubmit={onSignup}
        />

        <TextField
          value={lastName}
          id='lastName'
          label='Last Name'
          autoComplete='lastName'
          onChange={(e) => setLast(e.target.value)}
          handleSubmit={onSignup}
        /> */}
  
        <TextField
        value={email}
        id='email'
        label='Email'
        autoComplete='email'
        type='email'
        onChange={(e) => setEmail(e.target.value)}
        handleSubmit={onSignup}
        />
  
        <TextField
        value={password}
        id='password'
        label='Password'
        type='password'
        onChange={(e) => setPassword(e.target.value)}
        handleSubmit={onSignup}
        />
  
        <TextField
        value={password2}
        id='password2'
        label='Confirm Password'
        type='password'
        onChange={(e) => setPassword2(e.target.value)}
        handleSubmit={onSignup}
        />
      </>
      ): null}

      <div className='lake-games--signup-actions'>
        <Loader loading={loading} />
        <IconButton
          tooltipTitle='Signup'
          icon={HiOutlineChevronRight}
          onClick={onSignup}
          disabled={
            hideForm ||
            !username.length ||
            !password.length ||
            !password2.length ||
            !email.length
          }
        />
        <IconButton
          tooltipTitle='Forgot Password'
          icon={HiOutlineQuestionMarkCircle}
          onClick={() => history.push('/forgot')}
        />
        <IconButton
          tooltipTitle='Login'
          icon={HiClipboardCheck}
          onClick={() => history.push('/login')}
        />
        <a className="google-login" href={`${CURRENT_URL}accounts/google/login/?process=login`}>
          <GoogleButton />
        </a>

      </div>
    </div>
  );

  React.useEffect(() => {
    if (state.isAuthenticated) {
      history.push(previous?.location || '/home');
    }
  }, [state.isAuthenticated, previous, history]);

  return (
    <Grid
      items={[
        {
          id: 'gym-games--signup',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: signupForm,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Signup);
