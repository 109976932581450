import * as React from 'react';
import { Header } from '..';
import { useAuth } from '../../state-management';
import './styles.scss';

const Layout: React.FC = ({ children }) => {
  // eslint-disable-next-line no-console
  const { state, getUser } = useAuth();

  React.useEffect(() => {
    getUser().catch(({ error }) => {
      // eslint-disable-next-line no-console
      console.log('ERROR:', error);
    });
  }, [state.logname]);

  // eslint-disable-next-line no-console
  console.log('STATE:', state);

  return (
    <>
      <Header />
      <div className="layout">
        <div className="container">{children}</div>
      </div>
    </>
  );
};

export default Layout;
