import * as React from 'react'
import { RouteComponentProps, withRouter, useParams } from 'react-router-dom'
import {
  Grid,
  SettingsMenu,
} from '../../components'
import { useAuth } from '../../state-management'
import { getData } from '../../state-management/api'
import { User } from '../../state-management/types'
import './styles.scss'

interface UserParamType {
  username: string
}

interface UserProps {
  previous: {
    location: string
  }
}

const UserPage: React.FC<UserProps & RouteComponentProps> = ({
  history,
  previous,
}) => {
  const { state } = useAuth()

  const { username } = useParams<UserParamType>()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<number>(0)
  const [user, setUser] = React.useState<User>()
  
  React.useEffect(() => {
    const usernameQuery = username || state.logname

    setLoading(true)
    getData(`users/?username=${usernameQuery}`)
      .then(({ results }) => {
        console.log('RESULT:');
        console.log(results[0])
        if (results?.length) setUser(results[0])
        setLoading(false)
      })
      .catch(({ response }) => {
        setLoading(false)
        return response
      })
  }, [username, state.user])

  if (!state.isAuthenticated) history.push('/login')
  
  const userComponent = (
    <div className='lake-games--user'>
      <div className='lake-games--user-header'>
        <div className='lg-user--title'>
          <div className='lg-user--name'>{username}</div>
          {user?.id === state.user?.id ? <SettingsMenu /> : null}
        </div>

      </div>
    </div>
  )

  return (
    <Grid
      items={[
        {
          id: 'gym-games--user',
          cols: { xs: 12, sm: 8, md: 6, lg: 6 },
          content: userComponent,
          rows: 1,
        },
      ]}
    />
  )
}

export default withRouter(UserPage)
