import * as React from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, Button } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircle from '@material-ui/icons/AccountCircle';
import WaterIcon from '@mui/icons-material/Water';
import SearchIcon from '@material-ui/icons/Search';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EmojiEventsTwoToneIcon from '@material-ui/icons/EmojiEventsTwoTone';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { useAuth } from '../../state-management';
import {CURRENT_URL, LIFECYCLE} from '../../utils';
import './styles.scss';

interface MenuButtonProps {
  edge?: 'start' | 'end' | false;
}

interface MenuItemProps {
  to?: string;
  display: string | null;
  onClick?: () => void;
  icon?: any;
}

const MenuButtonLgComponent: React.FC<MenuButtonProps> = ({ edge }) => {
  const { logout, state } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout().then(() => console.log('Logged out'));
  };

  const CustomMenuItem: React.FC<MenuItemProps> = ({
    to,
    display,
    onClick,
    icon: Icon
  }) => {
    const menuI = (
      <MenuItem
        className="lg--header-menu-list-item-lg"
        onClick={onClick || handleClose}
        key={display}
      >
        {Icon ? <Icon className="header-icon" /> : null}
        {display}
      </MenuItem>
    );

    return to ? <Link to={to} className="header-menu-link">{menuI}</Link> : <div className="header-menu-link">{menuI}</div>;
  };

  const loggedInContent = (
    <div className="menu__btn_lg">
      <CustomMenuItem icon={HomeIcon} to="/home/" display="Home" />
      <CustomMenuItem icon={AccountCircle} to={`/user/${state.logname}`} display={state.logname} />

      <CustomMenuItem icon={SearchIcon} to="/search/" display="Search" />
      <CustomMenuItem icon={HelpOutlineIcon} to="/support/" display="Support" />

      {/* admin link isnt in the react app its an api url */}
      {state?.user?.is_superuser || state?.user?.is_staff ? (
        <a href={`${CURRENT_URL}admin/`} className="header-menu-link">
          <MenuItem
            className="lg--header-menu-list-item-lg"
            onClick={handleClose}
            key="admin"
          >
            <SupervisorAccountIcon className="header-icon" />
            Admin
          </MenuItem>
        </a>
      ) : null}

      <CustomMenuItem icon={ExitToAppIcon} onClick={handleLogout} display="Logout" />
    </div>
  );

  const strangerContent = (
    <div className="menu__btn_lg">
      <CustomMenuItem icon={AssignmentTurnedInIcon} to="/login/" display="Login" />
      <CustomMenuItem icon={PersonAddIcon} to="/signup/" display="Signup" />
    </div>
  );

  return (
    <>
        {state.isAuthenticated ? loggedInContent : strangerContent}
    </>
  );
};

MenuButtonLgComponent.defaultProps = {
  edge: false
};

export default MenuButtonLgComponent;
