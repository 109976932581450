import { api } from '../axios';
import { fixURI } from '../../utils';

const postData = (url: string, data: any): Promise<any> => {
  data.append('credentials', 'same-origin');

  return api()
    .post(fixURI(url), data)
    .then((response) => {
      if (response?.status === 201 || response?.status === 200) {
        setTimeout(() => console.log('Success:', response), 2000);
        return response;
      }
      throw Error(response?.statusText);
    })
    .catch(({ response }) => {
      return response;
    });
};

export default postData;
