import { Dispatch } from 'react';
import { AuthState, defaultState } from './auth-context';
import { getError } from '../../utils';
import {
  User,
} from '../types';

export type AuthAction =
  | { type: 'SET_AUTHENTICATION'; logname: string; user: User | undefined }
  | { type: 'START_AUTHENTICATION' }
  | { type: 'ERROR_AUTHENTICATION'; error: string }
  | { type: 'CLEAR_AUTHENTICATION' }
  | { type: 'RESET_AUTHENTICATION' };

export type AuthDispatch = Dispatch<AuthAction>;

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'START_AUTHENTICATION': {
      return { ...state, error: null, loading: true };
    }

    case 'ERROR_AUTHENTICATION': {
      const { error } = action;
      console.log('ERROR:', error);
      const parsedError = getError(error);
      console.log('PARSED ERROR:', parsedError);
      return { ...state, error: parsedError, loading: false };
    }

    case 'SET_AUTHENTICATION': {
      const { logname, user } = action;
      const exp = new Date(new Date().getTime() + 3600 * 1000).toString();

      localStorage.setItem('exp', exp);
      localStorage.setItem('logname', logname);
      localStorage.setItem('user', JSON.stringify(user));

      return {
        ...state,
        error: '',
        loading: false,
        isAuthenticated: true,
        logname,
        user,
        exp,
      };
    }

    case 'CLEAR_AUTHENTICATION': {
      localStorage.clear();
      return defaultState();
    }

    case 'RESET_AUTHENTICATION': {
      const logname = localStorage.getItem('logname');

      const user = JSON.parse(
        String(localStorage.getItem('user') || {})
      ) as User;

      const exp = localStorage.getItem('exp') || '';

      return {
        ...state,
        logname,
        user,
        exp,
        isAuthenticated:
          Boolean(logname) || Boolean(localStorage.getItem('logname')),
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
