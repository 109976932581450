import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
import { useAuth } from '../../state-management';
import './styles.scss';

const Home: React.FC<RouteComponentProps> = () => {
  const { state } = useAuth();
  
  const homeComponent = (
    <div className='lg-home'>
      <AppBar className='lg-home--actions'>
        <Toolbar className='lg-home--toolbar'>
          <h2>Welcome to TwoFit</h2>
        </Toolbar>
      </AppBar>
    </div>
  );

  return (
    <> {homeComponent} </>
    // <Grid
    //   items={[
    //     {
    //       id: 'lg-home',
    //       cols: { xs: 12, sm: 8, md: 6, lg: 4 },
    //       content: homeComponent,
    //       rows: 1
    //     }
    //   ]}
    // />
  );
};

export default withRouter(Home);
