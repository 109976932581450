import * as React from 'react';
import {
    HiOutlineQuestionMarkCircle,
    HiOutlineChevronRight,
    HiClipboardCheck,
} from 'react-icons/hi';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import GoogleButton from 'react-google-button';
import {Message, Grid, TextField, IconButton} from '../../components';
import {CURRENT_URL} from '../../utils';
import {useAuth} from '../../state-management';
import './styles.scss';

interface ForgotProps {
    previous: {
        location: string;
    };
}

const Forgot: React.FC<ForgotProps & RouteComponentProps> = ({history}) => {
    const {state, forgotPassword} = useAuth();

    const [email, setEmail] = React.useState('');

    const onForgot = () => {
        forgotPassword(email);
    };

    const forgotForm = (
        <div className='lake-games--forgot'>
            <div className='lake-games--forgot-header'>Forgot Password</div>

            <Message severity='error' message={state.error}/>
            <TextField
                value={email}
                id='email'
                label='Email'
                autoComplete='email'
                onChange={(e) => setEmail(e.target.value)}
                handleSubmit={onForgot}
            />

            <div className='lake-games--forgot-actions'>
                <IconButton
                    tooltipTitle='Forgot Password'
                    icon={HiOutlineChevronRight}
                    onClick={onForgot}
                    disabled={!email.length}
                />

                <IconButton
                    tooltipTitle='Login'
                    icon={HiOutlineQuestionMarkCircle}
                    onClick={() => history.push('/login')}
                />

                <IconButton
                    tooltipTitle='Signup'
                    icon={HiClipboardCheck}
                    onClick={() => history.push('/signup')}
                />

                <a className='google-login' href={`${CURRENT_URL}accounts/google/login/?process=login`}>
                    <GoogleButton/>
                </a>
            </div>
        </div>
    );

    return (
        <Grid
            items={[
                {
                    id: 'gym-games--forgot',
                    cols: {xs: 12, sm: 8, md: 6, lg: 4},
                    content: forgotForm,
                    rows: 1,
                },
            ]}
        />
    );
};

export default withRouter(Forgot);
