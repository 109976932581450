import { useContext } from 'react';
import { AuthContext, AuthState, UserPayload } from './auth-context';
import {getData, postData, putData, patchData, deleteData} from '../api';
import {
  User,
} from '../types';
import {getError} from '../../utils';

export interface UseAuth {
  (): {
    getUser: () => Promise<UserPayload>;

    login: (username: string, password: string) => Promise<void>;

    refresh: () => Promise<void>;

    signup: (
      username: string,
      password: string,
      password2: string,
      email: string,
      firstName: string,
      lastName: string
    ) => Promise<void>;

    changePassword: (
      newPassword: string,
      confirmPassword: string
    ) => Promise<void>;

    forgotPassword: (email: string) => Promise<void>;

    supportEmail: (subject: string, body: string) => Promise<void>;

    uploadProfile: (file: File | undefined) => Promise<void>;
    
    changeUsername: (username: string) => Promise<void>;

    logout: () => Promise<void>;

    state: AuthState;
  };
}

export const useAuth: UseAuth = () => {
  const { state, dispatch } = useContext(AuthContext);

  const getUser = async (): Promise<UserPayload> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return getData('users/')
      .then(({ results }) => {
        if (results?.length) {
          dispatch({
            type: 'SET_AUTHENTICATION',
            user: results[0],
            logname: results[0].username,
          });
          return results[0];
        }
        return null;
      })
      .catch(({ error }) => {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        });
        return error;
      });
  };

  const login = async (username: string, password: string): Promise<void> => {
    const formData = new FormData();
    formData.append('username', username.toLowerCase());
    formData.append('password', password);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/login/', formData)
      .then((res) => {
        if (res?.status === 200 && res?.data?.key) {
          localStorage.setItem('logname', username);
          // localStorage.setItem('token', res.data.key);

          dispatch({
            type: 'SET_AUTHENTICATION',
            logname: username.toLowerCase(),
            user: undefined,
          });
        } else {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        });
      });
  };

  const refresh = async (): Promise<void> => {
    dispatch({
      type: 'RESET_AUTHENTICATION',
    });
  };

  const signup = async (
    username: string,
    password: string,
    password2: string,
    email: string,
    firstName: string,
    lastName: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('username', username.toLowerCase());
    formData.append('password1', password);
    formData.append('password2', password2);
    formData.append('email', email);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('registration/', formData)
      .then((res) => {
        if (res?.status === 201 && res?.data?.key) {
          localStorage.setItem('logname', username);
          // localStorage.setItem('token', res?.data?.key);

          dispatch({
            type: 'SET_AUTHENTICATION',
            logname: username.toLowerCase(),
            user: undefined,
          });
        } else if (res?.status !== 201)
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        return res;
      })
      .catch((error) =>
        dispatch({
          type: 'ERROR_AUTHENTICATION',
          error,
        })
      );
  };

  const changeUsername = async (username: string): Promise<void> => {
    const formData = new FormData();
    formData.append('username', username.toLowerCase());

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return patchData('auth/user/', formData)
      .then((res: any) => {
        if (res?.status !== 200) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
          return res
        }
        localStorage.setItem('logname', username.toLowerCase());
        dispatch({
          type: 'SET_AUTHENTICATION',
          logname: username.toLowerCase(),
          user: state.user,
        });
        return res;
      })
      .catch((error) => {
        console.log('CHANGE USERNAME ERROR:', error);
      });
  };

  const changePassword = async (
    newPassword: string,
    confirmPassword: string
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('new_password1', newPassword);
    formData.append('new_password2', confirmPassword);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/password/change/', formData)
      .then((res) => {
        if (res?.status !== 200) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('CHANGE PASSWORD ERROR:', error);
      });
  };

  const forgotPassword = async (email: string): Promise<void> => {
    const formData = new FormData();
    formData.append('email', email);

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('auth/password/reset/', formData)
      .then((res) => {
        if (res?.status !== 201) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('FORGOT PASSWORD ERROR:', error);
      });
  };


  const uploadProfile = async (file: File | undefined): Promise<void> => {
    const formData = new FormData();
    formData.append('filename', file || '');

    dispatch({
      type: 'START_AUTHENTICATION',
    });

    return postData('profiles/', formData)
      .then((res) => {
        if (res?.status !== 201) {
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        }
        return res;
      })
      .catch((error) => {
        console.log('UPLOAD POST ERROR:', error);
      });
  };
  
  const supportEmail = async (subject: string, body: string): Promise<void> => {
    dispatch({
      type: 'START_AUTHENTICATION',
    });

    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('message', body);

    return postData('support-email/', formData)
      .then((res) => {
        if (res?.status !== 200)
          dispatch({
            type: 'ERROR_AUTHENTICATION',
            error: res?.data,
          });
        return res;
      })
      .catch((error) => {
        console.log('SUBMIT REPORT ERROR:', error);
      });
  };

  const logout = async (): Promise<void> => {
    const formData = new FormData();

    dispatch({
      type: 'CLEAR_AUTHENTICATION',
    });

    return postData('auth/logout/', formData).then(() =>
      window.location.reload()
    );
  };

  return {
    getUser,
    login,
    refresh,
    signup,
    changeUsername,
    supportEmail,
    uploadProfile,
    changePassword,
    forgotPassword,
    logout,
    state,
  };
};
