import * as React from 'react';
import { Link } from 'react-router-dom';
import { MenuButton } from '..';
import MenuButtonLg from '../menu-btn-lg'
import './styles.scss';
import Logo from '../logo';

const Header: React.FC = () => {
  return (
    <>
      <div className="header__small">
        <header className="header">
          <div className="header__toolbar flex container">
            <Link to="/welcome" className="header__logo flex">
              <Logo/>
            </Link>
            <MenuButton />
          </div>
        </header>
      </div>
      <div className="header__large">
        <header className="header">
          <div className="header__toolbar container">
            <Link to="/welcome" className="header__logo block float__left margin-right-10">
              <Logo/>
            </Link>
            <MenuButtonLg />
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
