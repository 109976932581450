import * as React from 'react';

const Logo: React.FC = () => {
  return (
    <>
    </>
  );
};

export default Logo;
