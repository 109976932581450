import * as React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { User } from '../../state-management/types';
import './styles.scss';

interface AvatarProps {
  user?: User;
}

const AvatarComponent: React.FC<AvatarProps> = ({ user }) => {
  return (
      <Avatar alt={user?.username.charAt(0)} src={user?.profile_data?.filename} />
  );
};

export default AvatarComponent;
