import * as React from 'react';
import { InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';
import './styles.scss';

interface SelectFieldProps {
  label?: string;
  required?: boolean;
  showOther?: boolean;
  value: any | null;
  values: any[] | null;
  setValue: (value: any) => void;
}
const SelectFieldComponent: React.FC<SelectFieldProps> = ({
  label,
  required,
  showOther,
  value,
  values,
  setValue,
}) => {
  return (
    <FormControl required={required} variant='outlined' className='lg-select'>
      <InputLabel className='lg-select--label' id='lg-select--label'>
        {label}
      </InputLabel>

      <Select
        id={label}
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        labelId='lg-select--label'
        label={`${label}:`}
        classes={{
          outlined: 'lg-select--outlined',
        }}
        MenuProps={{
          className: 'lg-select--menu',
          classes: {
            paper: 'lg-select--paper',
            list: 'lg-select--list',
          },
        }}
      >
        {values?.map((val, index) => (
          <MenuItem className='lg-select--item' key={index} value={val}>
            {val.name || val.username || val}
          </MenuItem>
        ))}
        {showOther ? (
          <MenuItem className='lg-select--item' key={-1} value={undefined}>
            Lake not listed
          </MenuItem>
          
        ) : null}
      </Select>
    </FormControl>
  );
};

export default SelectFieldComponent;
