import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import {
  Message,
  UserList,
  Grid,
  TextField,
} from '../../components';
import { getData, useAuth } from '../../state-management';
import { User } from '../../state-management/types';
import './styles.scss';

const Search: React.FC<RouteComponentProps> = () => {
  const { state } = useAuth();

  const [query, setQuery] = React.useState('');
  const [users, setUsers] = React.useState<User[]>([]);

  const handleSubmit = () => {
    getData(`search-users/?query=${query}`).then((data) => {
      setUsers(data);
    });
  };

  const searchComponent = (
    <div className='lg-search'>
      <div className='lg-search--header'>
        Search Users
      </div>
      <div className='lg-search--actions'>
        <TextField
          value={query}
          id='query'
          label='Search...'
          onChange={(e) => setQuery(e.target.value)}
          handleSubmit={handleSubmit}
        />

        <IconButton
          color='primary'
          onClick={handleSubmit}
          className='lg-search--btn'
          classes={{
            label: 'lg-search--btn-label',
          }}
        >
          <SearchRounded />
        </IconButton>
      </div>
      <Message severity='error' message={state.error} />
      <UserList users={users} />
    </div>
  );

  return (
    <Grid
      items={[
        {
          id: 'lg-search',
          cols: { xs: 12, sm: 8, md: 6, lg: 4 },
          content: searchComponent,
          rows: 1,
        },
      ]}
    />
  );
};

export default withRouter(Search);
