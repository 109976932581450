import * as React from 'react';
import {GMapify} from 'g-mapify';
import {MAPS_KEY} from '../../utils';
import  'g-mapify/dist/index.css';
import './styles.scss';


interface MapProps {
  lat: string,
  lng: string,
}


const Map: React.FC<MapProps> = ({ lat, lng }) => {
  const onMapSelect = (status: any, res: any) => console.warn(status, res);
  console.log('LAT:', lat)
  console.log('LNG:', lng)
  return (
    <>
      {lat && lng ? (
        <GMapify
          appKey={MAPS_KEY}
          lat={lat}
          lng={lng}
          onSelect={onMapSelect}
        />
      ) : null}
    </>
  );
};

export default Map;

