import {User} from '../state-management/types';

export const check = (query?: string | number | null): boolean =>
  query !== null &&
  query !== undefined &&
  query !== -1 &&
  query !== '-1' &&
  query !== 'All' &&
  query !== 'undefined' &&
  query !== 'null' &&
  query !== '';

export const LIFECYCLE = check(window.LIFECYCLE)
  ? window.LIFECYCLE
  : 'local';

export const getCurrentUrl = (hostname: string): string => {
  if (hostname === 'localhost') return 'http://localhost:8000/'
  
  return encodeURI(`https://${window.location.hostname}/`);
};

export const CURRENT_URL = getCurrentUrl(window.location.hostname)

export const APP_CODE = check(window.APP_CODE)
  ? window.APP_CODE
  : 'twofit';

export const STATIC_BUCKET = check(window.PUBLIC_BUCKET)
  ? window.PUBLIC_BUCKET
  : `dev-${APP_CODE}-public-content`

export const EPSAGON_TOKEN = check(window.EPSAGON_TOKEN)
  ? window.EPSAGON_TOKEN
  : '6a7c2c68-f9a6-45fa-a42f-4ab0a1469e80';

export const MAPS_KEY = check(window.MAPS_KEY)
  ? window.MAPS_KEY
  : 'fake-key';


export const isPrivileged = (user: User | undefined) => {
  return user?.is_superuser || user?.is_staff
}

const getTimeDiffStr = (num: number, str: string) => {
    let returnStr = str
    if (num !== 1)
      returnStr += 's'
    return `${num} ${returnStr} ago`
}

export const getTimeDiff = (created: string) => {
  
  const dateCreated = new Date(created)
  const today = new Date()
  const diff = today.getTime() - dateCreated.getTime();
  
  let msec = diff;
  const yy = Math.floor(msec / 1000 / 60 / 60 / 24 / 30 / 365.25);
  msec -= yy * 1000 * 60 * 60 * 24 * 30 * 365.25;
  const mo = Math.floor(msec / 1000 / 60 / 60 / 24 / 30);
  msec -= mo * 1000 * 60 * 60 * 24 * 30;
  const dd = Math.floor(msec / 1000 / 60 / 60 / 24);
  msec -= dd * 1000 * 60 * 60 * 24;
  const hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  const mm = Math.floor(msec / 1000 / 60);
  msec -= mm * 1000 * 60;
  const ss = Math.floor(msec / 1000);
  
  if (yy > 0) {
    return getTimeDiffStr(yy, 'year')
  }
  if (mo > 0) {
    return getTimeDiffStr(mo, 'month')
  }
  if (dd > 0) {
    return getTimeDiffStr(dd, 'day')
  }
  if (hh > 1) {
    return getTimeDiffStr(hh, 'hour')
  }
  if (mm > 1) {
    return getTimeDiffStr(mm, 'minute')
  }
  return getTimeDiffStr(ss, 'second')
  
}


export const getError = (data: any): string => {
  console.log('GETTING ERROR MSG:', data);

  if (data?.non_field_errors?.length) {
    return JSON.stringify(data.non_field_errors[0]);
  }
  if (data?.error) return data.error;
  if (data?.detail) return data.detail;
  if (data?.username?.length > 0) return data.username[0];
  if (data?.new_password2) return data.new_password2;
  if (data?.sport) return data.sport;
  if (data?.community) return data.community;

  if (data) return JSON.stringify(data);
  return data
};

export const fixURI = (url: string): string => {
  let ret = url;
  if (!url.includes('localhost')) ret = url.replace('http', 'https');
  return encodeURI(ret);
};


export const gcsBucket = `https://storage.googleapis.com/${STATIC_BUCKET}`;
