import * as React from 'react';
import { useAlert } from 'react-alert';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
} from '@material-ui/core';
import { useAuth } from '../../state-management';
import {
  Loader,
  Message,
  FileField,
  TextField,
  SelectField,
} from '../../components';
import './styles.scss';


const EditProfileForm: React.FC = () => {
  const {
    state,
    changeUsername,
    uploadProfile,
    getUser,
  } = useAuth();
  
  const alert = useAlert()
  
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [username, setUsername] = React.useState(String(state.user?.username));
  const [file, setFile] = React.useState<File>();
  const [address, setAddress] = React.useState<string>('');
  const [isPrivate, setIsPrivate] = React.useState<boolean>(Boolean(state.user?.profile_data?.is_private));
  const [birthday, setBirthday] = React.useState(String(state.user?.profile_data?.birthday));
  const [gender, setGender] = React.useState<string>(capitalize(String(state.user?.profile_data?.gender)));
  const genders = ['Male', 'Female'];

  function capitalize(str: string) : string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }


  const handleClick = () => {
    setOpen(!open);
    if (open) handleClose()
  };
  
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setMsg('');
    setFile(undefined);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (file)
      uploadProfile(file).then((res: any) => {
        if (res?.status === 201) {
          const msgText = 'Successfully updated contestant'
          setMsg(msgText);
          setTimeout(() => {
            handleClick()
            getUser()
            alert.show(msgText, { type: 'success' })
          }, 1000)
        }
      });

    if (username)
      changeUsername(username).then((res: any) => {
        if (res?.status === 200) {
          const msgText = 'Successfully updated username'
          setMsg(msgText);
          setTimeout(() => {
            handleClick()
            getUser()
            alert.show(msgText, { type: 'success' })
          }, 1000)
        }
      });

    const profile = state.user?.profile_data;

    if (profile) {

      if (address) profile.address = address;


      if (birthday) profile.birthday = birthday;

      if (gender) profile.gender = gender;

      let changeIsPrivate = false
      if (isPrivate !== profile?.is_private) {
        profile.is_private = isPrivate;
        changeIsPrivate = true;
      }

      // if (state.user && (address || birthday || gender || changeIsPrivate))
      //   updateProfile(profile, state.user).then((res: any) => {
      //     const msgText = 'Successfully updated profile'
      //     setMsg(msgText);
      //     setTimeout(() => {
      //       getUser()
      //       handleClick()
      //       alert.show(msgText, { type: 'success' })
      //     }, 1000)
      //   });
    }
  };

  return (
    <>
      <div
        onClick={handleClick}
        aria-controls='change-password-dialog'
        aria-haspopup='true'
      >
        Edit Profile
      </div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClick}
        aria-labelledby='edit-profile'
        className='lg-edit-profile'
      >
        <DialogTitle
          id='edit-profile'
          disableTypography
          className='lg-edit-profile--title'
        >
          Edit Profile
        </DialogTitle>

        <DialogContent className='lg-edit-profile--content'>
          <Message severity='error' message={state.error} />
          <TextField
            value={username}
            id='username'
            label='Change username'
            onChange={(e) => setUsername(e.target.value)}
            handleSubmit={handleSubmit}
          />

          <FileField label='Profile picture' required setValue={setFile} />

          {state.user?.profile_data ? (
            <>

              <TextField
                value={address}
                id='address'
                label='Lake Address'
                autoComplete='address'
                onChange={(e) => setAddress(e.target.value)}
                // handleSubmit={handleSubmit}
              />

              <TextField
                value={birthday}
                shrinkLabel
                id='birthday'
                type='date'
                label='Birthday'
                autoComplete='birthday'
                onChange={(e) => setBirthday(e.target.value)}
              />

              <SelectField
                value={gender}
                label='Gender participation'
                required
                values={genders}
                setValue={setGender}
              />

              <div>
                Is Private
                <Checkbox
                  checked={isPrivate}
                  value={isPrivate}
                  color='primary'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={(e) => {
                    if (e.target.checked) setIsPrivate(true)
                    else setIsPrivate(false)
                  }}
                />
              </div>

            </>
          ) : null}
        </DialogContent>

        <DialogActions className='lg-edit-profile--actions'>
          <Message
            severity={state.error ? 'error' : 'success'}
            message={state.error || msg}
          />
          <Loader loading={loading} />
          <Button
            onClick={handleSubmit}
            color='primary'
            className='lg-edit-profile--btn'
            classes={{ label: 'lg-edit-progress-card--btn-label' }}
          >
            Submit
          </Button>
          <Button
            onClick={handleClick}
            color='primary'
            className='lg-edit-profile--btn'
            classes={{ label: 'lg-edit-progress-card--btn-label' }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProfileForm;
