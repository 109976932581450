import React from 'react';
import epsagon from '@epsagon/web'
import { LIFECYCLE, APP_CODE, EPSAGON_TOKEN } from './utils';
import BaseRouter from './utils/router';
import { Layout } from './components';
import './style/main.scss';


// epsagon.init({
//   token: EPSAGON_TOKEN,
//   appName: `${LIFECYCLE}-${APP_CODE}-react`,
// })

declare global {
  interface Window {
    LIFECYCLE: string,
    API_URL: string;
    APP_CODE: string;
    EPSAGON_TOKEN: string;
    PUBLIC_BUCKET: string;
    MAPS_KEY: string;
  }
}

const App: React.FC = () => (
  <div className='lake-games'>
    <Layout>
      <BaseRouter />
    </Layout>
  </div>
);

export default App;
